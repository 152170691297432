import Cookies from "js-cookie";

export function userReducer(
  state = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
  action: any
) {
  switch (action.type) {
    case "LOGIN":
      return action.payload;
    case "CURRENTUSER":
      return action.payload;
    case "LOGOUT":
      return null;
    case "VERIFY":
      return { ...state, verified: action.payload };

    default:
      return state;
  }
}

export function dashboardReducer(
  state = Cookies.get("dashboard") ? JSON.parse(Cookies.get("dashboard")) : null,
  action: any
) {
  switch (action.type) {
    case "DASHBOARD":
      return action.payload;
    default:
      return state;
  }
}

