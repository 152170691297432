import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import("./pages/Register"));
const Activate = lazy(() => import("./pages/Activate"));
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Plans = lazy(() => import("./pages/Plans"));
const EditAccountDetails = lazy(() => import("./pages/EditAccountDetails"));
const EditBankDetails = lazy(() => import("./pages/EditBankDetails"));
const EditPassword = lazy(() => import("./pages/EditPassword"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const WithdrawalOrders = lazy(() => import("./pages/WithdrawalOrders"));
const Payment = lazy(() => import("./pages/Payment"));

const UnVerifiedRoutes = lazy(() => import("./routes/UnVerifiedRoutes"));
const NotLoggedInRoutes = lazy(() => import("./routes/NotLoggedInRoutes"));
const LoggedInRoutes = lazy(() => import("./routes/LoggedInRoutes"));
const DashboardRoute = lazy(() => import("./routes/DashboardRoute"));
const AdminRoutes = lazy(() => import("./routes/AdminRoutes"));

const App: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<div className="preloader" id="preloader"></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<About />} />

          <Route element={<LoggedInRoutes />}>
            <Route
              path="/edit-account-details"
              element={<EditAccountDetails />}
            />
            <Route
              path="/invest-with-officialbetivest"
              element={<Payment />}
            />
            <Route path="/edit-bank-details" element={<EditBankDetails />} />
            <Route path="/update-password" element={<EditPassword />} />
          </Route>

          <Route element={<DashboardRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>

          <Route element={<UnVerifiedRoutes />}>
            <Route path="/verify-account" element={<Activate />} />
          </Route>

          <Route element={<AdminRoutes />}>
            <Route path="/admin-dashboard-withdrawal-orders" element={<WithdrawalOrders />} />
          </Route>

          <Route element={<NotLoggedInRoutes />}>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ChangePassword />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
