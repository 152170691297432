import { combineReducers } from "redux";
import { userReducer, dashboardReducer } from "./userReducer";

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
